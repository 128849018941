.section--tau.launched .section__layout:after {
  background-image: url(../static/tau-logo.png);
}

.section--tau {
  .section__layout {
    // padding: 90px 40px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // justify-content: flex-end;
    padding: 80px 0 20px 0;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top center;
      opacity: .5;
      z-index: -1;
    }
  }

  .tau-overview {
    display: flex;
    flex-direction: column;
    color: #ffffff;
    margin-bottom: 40px;

    .tau-title {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .tau-second-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 30px;
    }

    .tau-content {
      font-size: 12px;
      font-weight: 200;
      line-height: 22px;
      margin-bottom: 30px;
    }

    .tau-link {
      display: flex;
      margin-top: 10px;
    }

    .tau-link-icon {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-right: 25px;

      span {
        background-color: #ffffff;
        height: 2px;

        &:nth-child(1) {
          width: 24px;
        }

        &:nth-child(2) {
          width: 16px;
        }
      }
    }

    .tau-link-content {
      font-size: 14px;
      font-weight: lighter;
    }
  }

  .tau-detail {
    display: none;
  }
  .hidden{
    visibility:hidden
  }
  .tau-list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .tau-list-item {
    .tau-list-item-header {
      cursor: pointer;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      margin-bottom: 26px;

      .tau-list-item-icon {
        background-image: url(../static/plus@2x.png);
        background-repeat: no-repeat;
        width: 11px;
        height: 11px;
        background-size: contain;
      }
    }

    .tau-list-item-content {
      margin-top: 17px;
      margin-bottom: 20px;
      display: none;
      font-weight: 200;
    }

    &.active {
      .tau-list-item-header {
        margin-bottom: 0;
      }

      .tau-list-item-content {
        display: block;
      }

      .tau-list-item-header {
        .tau-list-item-icon {
          background-image: url(../static/minus@2x.png);
        }
      }
    }

    &:last-child {
      .tau-list-item-content {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .section--tau {
    .section__layout {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      opacity: 1;
    }

    .tau-overview {
      width: 40%;
      margin-right: 100px;
      margin-bottom: 0;
    }

    .tau-second-title {
      margin-bottom: 37px;
    }

    .tau-content {
      margin-bottom: 48px;
    }

    .tau-list {
      width: 300px;
    }

    .tau-detail {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .tau-list-item {
      .tau-list-item-content {
        margin-bottom: 96px;
      }
    }
  }
}
